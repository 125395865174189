<template>
  <div class="md-layout">
    <ValidationObserver ref="form" class="md-layout-item md-size-100">
      <form @submit.prevent="onSubmit">
        <signup-card>
          <h2 class="title text-center" slot="title">Cadastro</h2>
          <div
            class="md-layout-item md-size-50 md-medium-size-50 md-small-size-100 mr-auto"
            slot="content-left"
          >
            <ValidationProvider
              name="nome"
              rules="required"
              v-slot="{ errors }"
            >
              <md-field
                class="md-form-group md-invalid"
                style="margin-bottom: 2rem"
              >
                <md-icon>face</md-icon>
                <label>Nome</label>

                <md-input v-model="user.name" />
                <validation-error :errors="errors" />
              </md-field>
            </ValidationProvider>
            <ValidationProvider
              name="e-mail"
              rules="required|email"
              v-slot="{ errors }"
            >
              <md-field
                class="md-form-group md-invalid"
                style="margin-bottom: 2rem"
              >
                <md-icon>email</md-icon>
                <label>Email</label>

                <md-input v-model="user.email" />
                <validation-error :errors="errors" />
              </md-field>
            </ValidationProvider>
            <ValidationProvider
              name="telefone"
              rules="required"
              v-slot="{ errors }"
            >
              <md-field
                class="md-form-group md-invalid"
                style="margin-bottom: 2rem"
              >
                <md-icon>phone</md-icon>
                <label>Fone</label>
                <md-input
                  v-model="user.phone"
                  class="input"
                  v-mask="'+55 (##) #####-####'"
                />
                <validation-error :errors="errors" />
              </md-field>
            </ValidationProvider>
            <ValidationProvider
              name="Ocupação"
              rules="required"
              v-slot="{ errors }"
            >
              <md-field
                class="md-form-group md-invalid"
                style="margin-bottom: 2rem"
              >
                <md-icon>assignment_ind</md-icon>
                <label>Ocupação</label>
                <md-select
                  v-model="user.occupation"
                  name="occupation"
                  id="occupation"
                >
                  <md-option value="Estudante">Estudante</md-option>
                  <md-option value="Profissional de Saúde"
                    >Profissional de Saúde</md-option
                  >
                  <md-option value="Gestor">Gestor</md-option>
                  <md-option value="Equipe da gestão de saúde"
                    >Equipe da gestão de saúde</md-option
                  >
                  <md-option value="Pesquisador">Pesquisador</md-option>
                  <md-option value="Jornalista">Jornalista</md-option>
                </md-select>
                <validation-error :errors="errors" />
              </md-field>
            </ValidationProvider>
            <ValidationProvider
              name="Senha"
              :rules="{
                regex: /^(?=.*[0-9])(?=.*[a-z]).{6,}$/,
                required: true
              }"
              v-slot="{ errors }"
              vid="password"
            >
              <md-field
                class="md-form-group md-invalid"
                style="margin-bottom: 2rem"
              >
                <md-icon>lock_outline</md-icon>
                <label>Password</label>
                <md-input v-model="user.password" type="password" />
                <validation-error :errors="errors" />
              </md-field>
            </ValidationProvider>
            <ValidationProvider
              name="Confirmação de senha"
              rules="confirmed:password"
              v-slot="{ errors }"
            >
              <md-field class="md-form-group md-invalid">
                <md-icon>lock_outline</md-icon>
                <label>Confirm Password</label>
                <md-input
                  v-model="user.password_confirmation"
                  type="password"
                />
                <validation-error :errors="errors" />
              </md-field>
            </ValidationProvider>
            <!--
              <md-checkbox v-model="boolean">
                Eu concordo com os <a>Termos e Condições</a>.</md-checkbox
              >
              -->
            <div class="button-container">
              <md-button
                class="md-indigo md-round mt-4"
                type="submit"
                slot="footer"
              >
                Cadastrar
              </md-button>
            </div>
          </div>

          <div
            class="md-layout-item md-size-50 md-medium-size-50 md-small-size-100 ml-auto"
            slot="content-right"
          >
            <div
              class="info info-horizontal"
              v-for="item in contentLeft"
              :key="item.title"
            >
              <div :class="`icon ${item.colorIcon}`">
                <md-icon>{{ item.icon }}</md-icon>
              </div>
              <div class="description">
                <h4 class="info-title">{{ item.title }}</h4>
                <p class="description">
                  {{ item.description }}
                </p>
              </div>
            </div>
          </div>
        </signup-card>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import { SignupCard, ValidationError } from "@/components";
import formMixin from "@/mixins/form-mixin";

export default {
  components: {
    SignupCard,
    ValidationError
  },
  mixins: [formMixin],
  data() {
    return {
      user: {
        name: null,
        boolean: false,
        email: null,
        phone: null,
        occupation: null,
        password: null,
        password_confirmation: null
      },
      contentLeft: [
        {
          colorIcon: "icon-success",
          icon: "timeline",
          title: "Construção de gráficos",
          description:
            "Com o Mosaico você poder obter graficos e série histórica, com poucos clicks"
        },
        {
          colorIcon: "icon-info",
          icon: "map",
          title: "Criação de mapas",
          description:
            "O Mosaico também facilita a criação de mapas de casos e incidência"
        },
        {
          colorIcon: "icon-danger",
          icon: "lock_outline",
          title: "Obs:.Senha",
          description:
            "Senha deve ter no mínimo 6 caracteres sendo ao menos uma letra um número"
        }
      ]
    };
  },

  methods: {
    async register() {
      /*
     if (!this.boolean) {
        await this.$store.dispatch(
          "alerts/error",
          "You need to agree with our terms and conditions."
        );
        return;
      }
    */

      try {
        await this.$store.dispatch("auth/register", this.user);
        await this.$store.dispatch(
          "alerts/success",
          "Successfully registered."
        );
      } catch (e) {
        console.log(e.response.data.errors);
        Object.entries(e.response.data.errors).forEach(async ([key, value]) => {
          await this.$store.dispatch("alerts/error", `${key}: ${value}`);
        });

        this.setApiValidation(e.response.data.errors);
      }
    },
    async alert(message) {
      await this.$store.dispatch("alerts/error", message);
    },
    async onSubmit() {
      this.$refs.form.validate().then(success => {
        if (!success) {
          let values = Object.values(this.$refs.form.errors);
          values.forEach(value => {
            value.forEach(message => {
              this.alert(message);
            });
          });
          return;
        }

        this.register();
        // Resetting Values
        //this.firstName = this.lastName = this.email = "";

        // Wait until the models are updated in the UI
        this.$nextTick(() => {
          //this.$refs.form.reset();
        });
      });
    }
  }
};
</script>
