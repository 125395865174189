var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"md-layout"},[_c('ValidationObserver',{ref:"form",staticClass:"md-layout-item md-size-100"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('signup-card',[_c('h2',{staticClass:"title text-center",attrs:{"slot":"title"},slot:"title"},[_vm._v("Cadastro")]),_c('div',{staticClass:"md-layout-item md-size-50 md-medium-size-50 md-small-size-100 mr-auto",attrs:{"slot":"content-left"},slot:"content-left"},[_c('ValidationProvider',{attrs:{"name":"nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('md-field',{staticClass:"md-form-group md-invalid",staticStyle:{"margin-bottom":"2rem"}},[_c('md-icon',[_vm._v("face")]),_c('label',[_vm._v("Nome")]),_c('md-input',{model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}}),_c('validation-error',{attrs:{"errors":errors}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"e-mail","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('md-field',{staticClass:"md-form-group md-invalid",staticStyle:{"margin-bottom":"2rem"}},[_c('md-icon',[_vm._v("email")]),_c('label',[_vm._v("Email")]),_c('md-input',{model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('validation-error',{attrs:{"errors":errors}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"telefone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('md-field',{staticClass:"md-form-group md-invalid",staticStyle:{"margin-bottom":"2rem"}},[_c('md-icon',[_vm._v("phone")]),_c('label',[_vm._v("Fone")]),_c('md-input',{directives:[{name:"mask",rawName:"v-mask",value:('+55 (##) #####-####'),expression:"'+55 (##) #####-####'"}],staticClass:"input",model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", $$v)},expression:"user.phone"}}),_c('validation-error',{attrs:{"errors":errors}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"Ocupação","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('md-field',{staticClass:"md-form-group md-invalid",staticStyle:{"margin-bottom":"2rem"}},[_c('md-icon',[_vm._v("assignment_ind")]),_c('label',[_vm._v("Ocupação")]),_c('md-select',{attrs:{"name":"occupation","id":"occupation"},model:{value:(_vm.user.occupation),callback:function ($$v) {_vm.$set(_vm.user, "occupation", $$v)},expression:"user.occupation"}},[_c('md-option',{attrs:{"value":"Estudante"}},[_vm._v("Estudante")]),_c('md-option',{attrs:{"value":"Profissional de Saúde"}},[_vm._v("Profissional de Saúde")]),_c('md-option',{attrs:{"value":"Gestor"}},[_vm._v("Gestor")]),_c('md-option',{attrs:{"value":"Equipe da gestão de saúde"}},[_vm._v("Equipe da gestão de saúde")]),_c('md-option',{attrs:{"value":"Pesquisador"}},[_vm._v("Pesquisador")]),_c('md-option',{attrs:{"value":"Jornalista"}},[_vm._v("Jornalista")])],1),_c('validation-error',{attrs:{"errors":errors}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"Senha","rules":{
              regex: /^(?=.*[0-9])(?=.*[a-z]).{6,}$/,
              required: true
            },"vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('md-field',{staticClass:"md-form-group md-invalid",staticStyle:{"margin-bottom":"2rem"}},[_c('md-icon',[_vm._v("lock_outline")]),_c('label',[_vm._v("Password")]),_c('md-input',{attrs:{"type":"password"},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),_c('validation-error',{attrs:{"errors":errors}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"Confirmação de senha","rules":"confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('md-field',{staticClass:"md-form-group md-invalid"},[_c('md-icon',[_vm._v("lock_outline")]),_c('label',[_vm._v("Confirm Password")]),_c('md-input',{attrs:{"type":"password"},model:{value:(_vm.user.password_confirmation),callback:function ($$v) {_vm.$set(_vm.user, "password_confirmation", $$v)},expression:"user.password_confirmation"}}),_c('validation-error',{attrs:{"errors":errors}})],1)]}}])}),_c('div',{staticClass:"button-container"},[_c('md-button',{staticClass:"md-indigo md-round mt-4",attrs:{"slot":"footer","type":"submit"},slot:"footer"},[_vm._v(" Cadastrar ")])],1)],1),_c('div',{staticClass:"md-layout-item md-size-50 md-medium-size-50 md-small-size-100 ml-auto",attrs:{"slot":"content-right"},slot:"content-right"},_vm._l((_vm.contentLeft),function(item){return _c('div',{key:item.title,staticClass:"info info-horizontal"},[_c('div',{class:("icon " + (item.colorIcon))},[_c('md-icon',[_vm._v(_vm._s(item.icon))])],1),_c('div',{staticClass:"description"},[_c('h4',{staticClass:"info-title"},[_vm._v(_vm._s(item.title))]),_c('p',{staticClass:"description"},[_vm._v(" "+_vm._s(item.description)+" ")])])])}),0)])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }